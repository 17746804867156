(function ($) {

  var app = window.app || {};

  // Initial configs
  app.debug = false;



  $(document).ready(function () {

    $(document).scroll(function () {
      if ($(this).scrollTop() > 120) {
        $("nav").addClass("-min");
      } else {
        $("nav").removeClass("-min");
      }
    });


    $('.menu a, .go-down').on('click', function (e) {
      e.preventDefault();

      $('html, body').animate({
        scrollTop: $($(this).attr('href')).offset().top - 100
      }, 500, 'linear');
    });


  });

})(jQuery);